import * as Yup from "yup";

export const offerFormSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  status: Yup.string().required("Status is required"),
  type: Yup.string().trim().required("Type is required"),
  subTitle: Yup.string().trim().required("Sub title is required"),
  code: Yup.string().when("type", {
    is: "Coupon",
    then: Yup.string().required("Code is required"),
  }),
  validity: Yup.string().required("Validity is required"),
  productId: Yup.string().required("Product is required"),
  details: Yup.array().required("Details are required"),
  note: Yup.string().trim().nullable(),
  redirectLink: Yup.string().required("Redirect link is required"),
  is_featured: Yup.boolean().required("Featured is required"),
  banner: Yup.array().when("is_featured", {
    is: true,
    then: Yup.array().required("Banner is required"),
  }),
});
